//+++++++++++++++++++++++
//+++ HELPERS: COLORS +++
//+++++++++++++++++++++++

// Basic colors
$red: #F44336;
$pink: #E91E63;
$purple: #5F259F;
$deep-purple: #673AB7;
$indigo: #3F51B5;
$blue: #217BBD;
$light-blue: #03A9F4;
$cyan: #00BCD4;
$teal: #009688;
$green: #318713;
$light-green: #8BC34A;
$lime: #CDDC39;
$yellow: #FFEB3B;
$amber: #FFC107;
$orange: #FF9800;
$deep-orange: #FF5722;
$brown: #795548;
$cream: #F2F2F2;
$white: #FFFFFF;
$grey: #374A53;
$black: #222222;

$lmcgreen: #509E2F;
$lmcblue: #00A9E0;

// Standard greyscale: number = brightness (HSB)
$grey95: #F2F2F2;
$grey90: #E6E6E6;
$grey80: #CCCCCC;
$grey70: #B3B3B3;
$grey60: #999999;
$grey50: #808080;
$grey40: #666666;
$grey30: #595959;
$grey20: #333333;
$grey15: #262626;

// Social colors
$dribbble: #EA4C89;
$facebook: #3B5999;
$flickr: #FF0084;
$googleplus: #DD4B39;
$instagram: #E4405F;
$linkedin: #0077B5;
$pinterest: #BD081C;
$snapchat: #FFFC00;
$soundcloud: #ff3300;
$tumblr: #34465D;
$twitter: #55ACEE;
$vimeo: #1AB7EA;
$whatsapp: #25D366;
$youtube: #CD201F;

// Social colors
.dribbble { color: $dribbble; };
.facebook { color: $facebook; };
.flickr { color: $flickr; };
.googleplus { color: $googleplus; };
.instagram { color: $instagram; };
.linkedin { color: $linkedin; };
.pinterest { color: $pinterest; };
.snapchat { color: $snapchat; };
.soundcloud { color: $soundcloud; };
.tumblr { color: $tumblr; };
.twitter { color: $twitter; };
.vimeo { color: $vimeo; };
.whatsapp { color: $whatsapp; };
.youtube { color: $youtube; };

